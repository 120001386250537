import * as _vue4 from "vue";
var _vue3 = "default" in _vue4 ? _vue4.default : _vue4;
var exports = {};
exports.__esModule = true;
exports.default = scrollIntoView;
var _vue = _vue3;
var _vue2 = _interopRequireDefault(_vue);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function scrollIntoView(container, selected) {
  if (_vue2.default.prototype.$isServer) return;
  if (!selected) {
    container.scrollTop = 0;
    return;
  }
  var offsetParents = [];
  var pointer = selected.offsetParent;
  while (pointer && container !== pointer && container.contains(pointer)) {
    offsetParents.push(pointer);
    pointer = pointer.offsetParent;
  }
  var top = selected.offsetTop + offsetParents.reduce(function (prev, curr) {
    return prev + curr.offsetTop;
  }, 0);
  var bottom = top + selected.offsetHeight;
  var viewRectTop = container.scrollTop;
  var viewRectBottom = viewRectTop + container.clientHeight;
  if (top < viewRectTop) {
    container.scrollTop = top;
  } else if (bottom > viewRectBottom) {
    container.scrollTop = bottom - container.clientHeight;
  }
}
export default exports;
export const __esModule = exports.__esModule;